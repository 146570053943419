////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// D) FUNCTIONAL PART
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//	SCREEN-CLASS
	var mobileBody = function(){
		$('body').addClass('mobile');
		if(mediaType=='monitor') $('body').removeClass('mobile');
	};

//	INITIALIZE SLICK SLIDER for BLog

	var initSlickNews = function(classSelectorNews){
		var $cardNews = $(classSelectorNews);
		$cardNews.not('.slick-initialized').slick({
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			arrows : false
		});
		Foundation.reInit('equalizer');
		if(mediaType=='monitor') $cardNews.slick('unslick');
	};

// TEASERSLIDER
	var initSlickTeaserNav = function() {
		$('.teaser-content-wrapper').slick({
			dots: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			centerMode: false,
			variableWidth: true,
			arrows : false
		});
	};

	var initSlickEmployee = function(slickEmloyeeClass) {
		$(slickEmloyeeClass).slick({
			dots: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			centerMode: false,
			variableWidth: false,
			arrows : false
		});

	};


	var initSlickVideo = function(slickVideoClass) {
		$(slickVideoClass).slick({
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			centerMode: false,
			variableWidth: false,
			arrows : true,
			useCSS: false // IE BUGFIX - SO SECOND SLIDE IS NOT SHOWN TO EARLY
		});
	};

//	INITIALIZE SLICK SLIDER for News

	var initSlickEvents = function(classSelectorEvents){
		var $cardNews = $(classSelectorEvents);
		$cardNews.not('.slick-initialized').slick({
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			arrows : false
		});
		Foundation.reInit('equalizer');
		if(mediaType=='monitor') $cardNews.slick('unslick');
	};


	var initSlickSmallEvent = function(classSelectorSmallEvents){
		var $cardNews = $(classSelectorSmallEvents);
		$cardNews.not('.slick-initialized').slick({
			dots: true,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			centerMode: false,
			variableWidth: false,
			arrows : false
		});
	};

//	INITIALIZE SLICK SLIDER for Header

	var initSlick = function(classSelector){
		var $card = $(classSelector);
		$card.not('.slick-initialized').slick({
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			centerMode: false,
			variableWidth: true,
			arrows : false
		});
	};

//	INITIALIZE SLICK SLIDER for Gallery
	var initSlickGallery = function(classSelectorGallery){
		var $card = $(classSelectorGallery);
		$card.not('.slick-initialized').slick({
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			centerMode: false,
			variableWidth: false,
			arrows : true
		});
	};

	var slickHeaderWidth = function() {
		if($('.media-main .slick-slider .slick-slide').length > 0){
			var windowWidth = $(window).width(),
				sliderEl = $('.media-main .slick-slider .slick-slide');
			sliderEl.css('width', windowWidth);
		}
	};


	// INIT MAIN MENU
	var calcMainMenu = function(){
		// Width (padding) für main dropdown berechnen / left space für zweites level in dropdown berechnen
		var widthNav = $('.nav-main').outerWidth();
		var overflowWidthMenu = $('.wrapper-main').width() - $('.nav-main').outerWidth(),
			paddingMenu = overflowWidthMenu/2,
			extraPadding =  widthNav / 100 * 33,
			widthFirstLevel = $('.nav-main .menu.submenu li:last-child').outerWidth(),
			leftSpaceSecond = widthFirstLevel + paddingMenu,
			$subMenu = $('.nav-main .menu.submenu'),
			$subSubMenu = $('.nav-main .menu.submenu li.is-dropdown-submenu-parent ul.submenu');


		//$subMenu.css({paddingLeft: paddingMenu, paddingRight: paddingMenu + extraPadding}); // Inkl. Teaser-Slider
		$subMenu.css({paddingLeft: paddingMenu, paddingRight: paddingMenu});	// No Teaser-Slider
		//$subSubMenu.css({left: leftSpaceSecond});
		var $widthTeaser =  $subMenu.width() / 2;
		$('.nav-main .teaser-content').css('width', $widthTeaser);

	};

// 	BUILD TOC MENU STRUCTURE

	var buildMenuTOC = function() {

		// find all content elements which should arise in the toc menu
		var tocEntries = '.content-main .csc-default[data-sectionindex=1]';

		// find place for toc menu
		var tocMenuUL = 'ul.toc-list';

		// collect toc entry data
		var tocEntry = '';
		$(tocEntries).each(function (index) {
			var tocSectionName = $(this).data('sectionname');
			var tocOrigUid = $(this).data('origuid');
			var tocCtype = $(this).data('ctype');
			var tocSectionTarget = $(this).attr('id');
			tocEntry += '<li class="" data-toc="' + tocSectionTarget + '" data-origuid="' + tocOrigUid + '" data-ctype="' + tocCtype + '">';
			tocEntry += '<a href="#' + tocSectionTarget + '" target="_self">';
			tocEntry += tocSectionName;
			tocEntry += '</a></li>';
		});

		// check if a call-to-action button is available
		var tocMenuEnter = $(tocMenuUL).find('li');

		if ($(tocMenuEnter).length > 0) {
			// call-to-action button available - put toc-menu before the cta-button
			$(tocMenuEnter).before(tocEntry);
		} else {
			// NO call-to-action button found - append toc-menu to ul
			$(tocMenuUL).append(tocEntry);
		}

		getTocMenuDimension();
		activeToc();
	};

	var getTocMenuDimension = function(){
		$(menuToc.class+' li').each(function(){
			menuToc.contentWidth +=$(this).outerWidth();
		});

		menuToc.menuWidth = $('.nav-toc').outerWidth();
		menuToc.arrowWith = $(menuToc.class+' .arrows').outerWidth();
		menuToc.availableDistance = menuToc.contentWidth - menuToc.menuWidth;
	};

//	AKTIVE AUSZEICHNUNG TOC NAV

	var activeToc = function(){
		var containerToc = '.controls-main';
		$(containerToc+' '+menuToc.class+' ul li').each(function(index) {
			if($(this).data( "toc" )!=null){
				$('.content-main').find('#'+$(this).data( "toc" )).bind('inview', function (event, visible) {
					$(containerToc+' '+menuToc.class+' ul').find('.current').removeClass('current');
					if (visible === true) {
						//$(containerToc+' '+menuToc.class+' ul').find("[data-toc='" + $(this).attr('id')+ "'] a").addClass('act');
						$(containerToc+' '+menuToc.class+' ul').find("[data-toc='" + $(this).attr('id')+ "']").addClass('act');
					} else {
						//$(containerToc+' '+menuToc.class+' ul').find("[data-toc='" + $(this).attr('id')+ "'] a").removeClass('act');
						$(containerToc+' '+menuToc.class+' ul').find("[data-toc='" + $(this).attr('id')+ "']").removeClass('act');
					}

					//$(containerToc+' '+menuToc.class+' ul').find('.act').eq(0).addClass('current');
					//$(containerToc+' '+menuToc.class+' ul').find('.act').eq(0).attr('id','current');

					var $stage = $(containerToc+' '+menuToc.class+' ul').find('li.act').eq(0);
					$stage.addClass('current');

					setTimeout(function(){
						menuToc.timerValue=tocHorizontalAutoScroll($stage);
					}, 300);

				});
			}
		});

		/*
		//	ADD CLICK-HANDLER TO TOC-NAV LEFT-RIGHT ARROWS
		$('.scroll-helper .arrow-left').bind('click',function(){
			$(menuToc.class).stop().animate({scrollLeft:'-=200px'}, 500, 'swing', function(){
				updateArrowScrollState($(this).scrollLeft());
			});
		});
		$('.scroll-helper .arrow-right').bind('click',function(){
			$(menuToc.class).stop().animate({scrollLeft:'+=200px'}, 500, 'swing', function(){
				updateArrowScrollState($(this).scrollLeft());
			});
		});
		*/
	};


//  AUTO SCROLL HORIZONTAL

	var tocHorizontalAutoScroll = function(el){

		clearTimeout(menuToc.timerValue);
		var $el = $(el),
			navigationPosition = $('.nav-toc, .toc-list-calendar').scrollLeft(),
			distanceLeft = 0,
			currentPosition = false;

		try {
			currentPosition = $('.nav-toc').offset().left - $el.offset().left;
		} catch(err) {
			currentPosition = false;
		}

		if(currentPosition) {
			distanceLeft =  navigationPosition + (currentPosition * -1);
			$('.nav-toc, .toc-list-calendar').stop().animate({scrollLeft:distanceLeft+'px'}, 500, 'swing', function(){
				updateArrowScrollState($('.nav-toc').scrollLeft());
			});

			$('.nav-toc, .toc-list-calendar').stop().animate({scrollLeft:distanceLeft+'px'}, 500, 'swing', function(){
				updateArrowScrollState($('.nav-toc').scrollLeft());
			});
		}
	};


	var updateArrowScrollState = function(pos){
		var $arrowLeft =  $('.scroll-helper .arrow-left'),
            $arrowRight =  $('.scroll-helper .arrow-right');

		$arrowLeft.removeClass('visible');
		$arrowRight.removeClass('visible');
		/*

		if(menuToc.availableDistance > 0){
			if(pos>menuToc.arrowWith) {
				$arrowLeft.addClass('visible');
			}else{
				$arrowLeft.removeClass('visible');
			}

			if(pos<=menuToc.availableDistance){
				$arrowRight.addClass('visible');
			}else{
				$arrowRight.removeClass('visible');

			}
		}else{
			//	INHALT MUSS NICHT GESCROLLT WERDEN
			$arrowLeft.removeClass('visible');
			$arrowRight.removeClass('visible');
		}

		console.log('pos',pos);
		console.log('tocSettings',menuToc);
		*/
	};

	/*
	var getTocContentWidth = function(){
		tocWidth = $('.toc-list-wrapper ul li').outerWidth();
	};
	*/


//	SET BACK-TO-TOP-BUTOTN VISIBLE

	var initBackToTop = function () {
		var backToTop = $('[data-functionality*="' + menuTopLink + '"]');
		$(window).scroll(function(){
			if ($(this).scrollTop() > 500) {
				$(backToTop).fadeIn();
			} else {
				$(backToTop).fadeOut();
			}
		});
		$(backToTop).bind('click', function(e) {
			$('body,html').animate({scrollTop:0}, 800);
		});
	};


//	BIND CLICK FUNCTION TO HEADER

	var initAdditionalFormHandler = function(){
		$('#tiny-searchform fieldset label, #searchformMobile fieldset label').on('click', function(){
			$(this).parentsUntil('div').submit();
		});
	};


 var metaNavigation = function(){
	var widthRow = $('.nav-meta-wrapper .row').width();
	var widthWindow = $(window).width();
	var widthBg =  (widthWindow - widthRow) / 2;
	$('.nav-quickaccess .state .bg-state').css('width', widthBg + 158);
};


var metaDropdown = function(){
	$('.nav-meta li.dropdown a').click(function() {
		$(this).change();
	});
	$('.nav-meta li.dropdown').on('change', function(){
		var $thisLi = $(this).find('li.submenusub'),
			widthLi = $thisLi.outerWidth(),
			countLi = $thisLi.length;
		$(this).find('ul.menu').css('width', widthLi*countLi);
	})
};

var searchToggle = function(){
	$('.search-toggle').on('click', function(){
		$(this).next('form').toggleClass('open');
		$(this).toggleClass('open');
		$('input[name="tx_indexedsearch_pi2[search][sword]"]').focus();
		return false;
	});
};

var initParallax = function () {
	$('.parallax-window').parallax();
};
var initParallaxHeader = function () {
	$('.parallax-window-header').parallax({
		naturalWidth: 2224,
		naturalHeight: 850
	});
};


var initAccParaCallback = function() {
	// FIX ISSUE WHERE ACCORDION IS OVERFLOWING PARALLAX
	$('.accordion')
		.on("down.zf.accordion", function() {
			$(window).trigger('resize.px.parallax');
		})
		.on("up.zf.accordion", function() {
			$(window).trigger('resize.px.parallax');
		});
};

var initTextImageParallaxCallback = function() {
	var lazyImage = $('img.lazyload');
	lazyImage.on('load', function(){
		$(window).trigger('resize.px.parallax');
	});
}


var initEmployeeDetail = function () {
	$('.detail-link-employee').on('click', function(){
		var singleEmployee = '.single-employee';
		var $closestEmployee = $(this).closest(singleEmployee);
		if($(singleEmployee).hasClass('open')) $(singleEmployee).removeClass('open');
		$closestEmployee.toggleClass('open');
		var windowWidth = $(window).width();
		$closestEmployee.find('.employee-detail').css('width',windowWidth);
	});
	$(document).on("click", function (e) {
		if ($(e.target).is('.detail-link-employee') === false) {
			$('.single-employee').removeClass("open");
		}
	});

};

var initFloating = function(){
	// trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
	if (!String.prototype.trim) {
		(function() {
			// Make sure we trim BOM and NBSP
			var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
			String.prototype.trim = function() {
				return this.replace(rtrim, '');
			};
		})();
	}

	[].slice.call( document.querySelectorAll( 'input.input__field' ) ).forEach( function( inputEl ) {
		// in case the input is already filled..
		if( inputEl.value.trim() !== '' ) {
			classie.add( inputEl.parentNode, 'input--filled' );
		}

		// events:
		inputEl.addEventListener( 'focus', onInputFocus );
		inputEl.addEventListener( 'blur', onInputBlur );
	} );

	function onInputFocus( ev ) {
		classie.add( ev.target.parentNode, 'input--filled' );
		classie.add( ev.target.parentNode, 'input--line--filled' );
	}

	function onInputBlur( ev ) {
		if( ev.target.value.trim() === '' ) {
			classie.remove( ev.target.parentNode, 'input--filled' );
			classie.remove( ev.target.parentNode, 'input--line--filled' );
		}else{
			classie.remove( ev.target.parentNode, 'input--line--filled' );
		}
	}
};


// RECHTE SPALTE
var initCourseRightAcc = function() {
	var $rightColEl = $('.sticky-right-metacontent .csc-default');
	$rightColEl.each(function(){
		var $header = $(this).find('header'),
			$thisP = $(this).find('header').next();
		$thisP.toggleClass('container');

		$header.on('click', function(){
			var $thisParent = $(this).parent();
			$thisParent.toggleClass('open');
		})
	});
};


var initLesson = function() {
	var $lesson = $('.calc-lesson');
	// COUNT FROM HEIGHEST AS 100%
	var $countLessons = [];
	$lesson.each(function() {
		$countLessons.push($(this).attr('data-count'));
	});
	var heighestLessonCount = Math.max.apply(Math, $countLessons);
	$lesson.each(function(){
		var $total = heighestLessonCount;
		var $numberOfLessons = $(this).attr('data-count');
		var percent = 100 / $total * $numberOfLessons;

		$(this).css('width', percent+'%');
	});

	// SUM Of ALL LESSONS Calc to All Lessons
	/*$lesson.each(function() {
		lessons = lessons + parseInt($(this).attr('data-count'));
		$('.lesson-total').html(lessons);
	});


	$('.calc-lesson').each(function(){
		var $total = $('.lesson-total').text();
		var $numberOfLessons = $(this).attr('data-count');
		var percent = 100 / $total * $numberOfLessons;

		$(this).css('width', percent+'%');
	});
*/

};

var initPriceTable = function() {
	var $priceTable = $('.price-table');
	$priceTable.each(function() {
		var $totalPrice = $(this).find('th:nth-child(2)').text();
		var $totalText = $(this).find('th:nth-child(1)').text();
		var $priceSlot = $(this).parent().prev('.accordion-title');
		$priceSlot.append('<div class="total-price-overview">'+$totalText+'<br /><b>'+$totalPrice+'</b></div>');
		$priceSlot.addClass('price-accordion');
	});
};


var playCourseVideo = function() {
	var video=document.getElementById("courseVideo");
	video.play();
};


// HOOME
var specialVideoFeature = function() {

	var $nextButton = $('.slick-slider-video .slick-next');
	var $prevButton = $('.slick-slider-video .slick-prev');
	var $sliderTextbox = $('.home-slider .slider-element-wrapper');
	var $sliderButton = $('.home-slider .slider-button-wrapper');

	$('.home-slider.video .slide-video-special.slick-slide video').trigger('pause');
	$prevButton.hide();
	$('.slick-slider-video .slick-active video').trigger('play');

	$nextButton.on('click', function(){

		var activeVideoId = $('.slick-slider-video .slick-active video').attr('id');
		var $activeVideo = $('.slick-slider-video video#'+activeVideoId+'');

		$activeVideo.trigger('play');
		$activeVideo.prop('loop', false);

		$(this).hide();
		$sliderTextbox.hide();
		$sliderButton.hide();
		$prevButton.show();
	});

	$prevButton.on('click', function(){
		$('.home-slider.video .slide-video-special.slick-slide video').trigger('pause');

		var activeVideoId = $('.slick-slider-video .slick-active video').attr('id');
		var $activeVideo = $('.slick-slider-video video#'+activeVideoId+'');

		$activeVideo.trigger('play');
		$activeVideo.prop('loop', true);
		$nextButton.show();
		$sliderTextbox.show();
		$sliderButton.show();
		$(this).hide();
	});
};


// 	COOKIE

	function initCookie(){
		$('.close-cookie').on('click', function(){
			Cookies.set('likecookie', 'true', { expires: 365 });
			$('.we-love-cookies').hide();
		});

		if(Cookies.get('likecookie')){
			$('.we-love-cookies').hide();
		} else {
			$('.we-love-cookies').show();
		}
	};


// Video in Fancy Box
var initFancyVideo = function() {
	$('.fancybox-media.extern').fancybox({
		openEffect  : 'none',
		closeEffect : 'none',
		helpers : {
			media : {}
		}
	});

	$('.fancybox-media.intern').fancybox({
		openEffect  : 'none',
		closeEffect : 'none',
		type: 'iframe',
		width: '640px',
		height: '400px',
		helpers : {
			media : {}
		}
	});
}

// Count Lessons
var initLessonsCount = function() {
	let hwdtotal = 0;
	let tktotal = 0;
	let counttotal = 0;

	$('.single-lesson .hwd-count').each(function() {
		if($(this).data('hwdcount') != '') {
			hwdtotal = hwdtotal + parseInt($(this).data('hwdcount'));
		}
	});
	$('.single-lesson .tk-count').each(function() {
		if($(this).data('tkcount') != '') {
			tktotal = tktotal + parseInt($(this).data('tkcount'));
		}
	});
	$('.single-lesson .total-count').each(function() {
		if($(this).data('countall') != '') {
			counttotal = counttotal + parseInt($(this).data('countall'));
		}
	});

	$('.total-hwd').html(hwdtotal);
	$('.total-tk').html(tktotal);
	$('.total-all').html(counttotal);
}


// Navigation Items with filter function
var filterFunctionNavigation = function(){
	var urlParams = new URLSearchParams(window.location.search);
	var has_filterid = urlParams.has('filterid');
	var url_filterid = urlParams.get('filterid');

	if(has_filterid) {
		if ($('.filter-list').length > 0) {
			$('.' + url_filterid + '').click();
		}
	}
};

var schulanmeldungForm = function() {
	// prevent submit on enter
	const form = document.querySelector('form.powermail_form_72');
	form.addEventListener('keydown', function(event) {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	});
	// treatment for nationality/place of origin
	const selectElement = document.getElementById('powermail_field_nationalitaet');
	const heimatortField = document.getElementById('powermail_field_heimatort');
	selectElement.addEventListener('change', function() {
		const selectedValue = selectElement.value;
		console.log(selectedValue);
		if (selectedValue !== 'Schweiz') {
			heimatortField.value = selectedValue;
			heimatortField.focus();
		} else {
			heimatortField.value = '';
		}
	});
};
